.quill-editor .ql-container.ql-snow {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.ql-tooltip {
  left: 0 !important;
  transform: translateX(0) !important;
}

.ql-snow .ql-picker-label img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.ql-editor.ql-blank::before {
  color: #A0AEC0 !important;
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-style: normal !important;
}

.quill-editor .ql-editor {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #000000;
  line-height: 1.5;
}

.ql-editor {
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.ql-toolbar {
  font-size: 14px;
}

.ql-container {
  font-size: 16px;
  font-family: inherit;
  line-height: 1.5;
}
