body {
    background-color: #f8f9fa!important;
    box-sizing: border-box;
}

.custom-date {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

input[type="text"]:disabled, 
input[type="password"]:disabled {
    color: #000;
    background: transparent;
}

body > iframe {
    display: none;
}

.chakra-modal__content-container,
.chakra-modal__overlay,
.chakra-modal__content {
    z-index: 1000 !important;
}

.echarts-for-react {
    > div {
        padding: 0 !important;
    }
}
